$footerHeight: 50px;

@keyframes flash {
  from {
    background-color: white;
  }

  to {
    background-color: black;
  }
}

.nft-aggregator {
  color: white;
  font-family: 'Roboto Slab', serif;
  height: 100vh;
  overflow: hidden;

  button.enter {
    background-color: rgba(255, 255, 255, 0.9);
    border: 2px solid transparent;
    cursor: pointer;
    display: block;
    font-size: 24px;
    height: 48px;
    opacity: 0;
    position: fixed;
    top: calc(50vh - 24px);
    transition: all 300ms;
    left: calc(50% - 45px);
    width: 90px;
    z-index: 4;

    &.loaded {
      opacity: 1;
    }

    &.hide {
      display: none;
    }

    &:hover {
      background-color: transparent;
      border-color: white;
      color: white;
    }
  }

  header.main-header { 
    align-items: center;
    background-color: rgba(0,0,0,.8);
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: flex-end;
    left: 0;
    margin-bottom: 8px;
    position: fixed;
    padding: 0 12px;
    top: 0;
    transition: all 600ms;
    width: 100vw;
    z-index: 3;

    .description {
      padding-bottom: 32px;
      transition: all 600ms;

      p {
        text-align: center;
      }
    }

    .settings {
      cursor: pointer;
      display: none;
    }

    &.hide {
      align-content: flex-start;
      flex-direction: row;
      height: 50px;
      justify-content: center;

      .description {
        display: none;
        opacity: 0;
      }

      .settings {
        display: block;
      }

      h2 {
        width: 100%;
      }
    }

    h2 {
      margin: 0;
      transition: all 600ms;
      width: 248px;
    }

    p {
      margin: 0;
    }
  }

  .results-container {
    overflow: hidden;
    height: calc(100vh - #{$footerHeight});
    padding: 50px 12px 0;
    width: 100vw;

    .results-table {
      height: 100%;

      button {
        background-color: rgba(255, 255, 255, 0.8);
        border: none;
        border-radius: 0;
        cursor: pointer;
        font-size: 24px;
        height: 48px;
        margin: 6px 0;
        width: 100%;
      }
    }

    .nft-container {
      align-items: center;
      background-color: rgba(255, 255, 255, 0.2);
      color: white;
      cursor: pointer;
      display: inline-flex;
      flex-direction: column;
      height: calc((100% / 3) - 4px);
      margin-bottom: 6px;
      overflow: hidden;
      outline: none;
      padding-top: 6px;
      position: relative;
      text-decoration: none;
      vertical-align: top;
      width: calc(50% - 3px);

      a {
        color: white;
        outline: none;
        position: absolute;
        text-decoration: none;
        transform: scaleX(-1);
        width: 100%;
      }

      &:nth-child(even) {
        margin-left: 6px;
      }

      .nft-video,
      .nft-image,
      .img {
        height: calc(100% - 32px);;
        margin: 0 auto;
        outline: none;
        transition: height 300ms;
        width: 100%;
      }

      .img {
        animation-name: flash;
        animation-duration: 1200ms;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }

      .nft-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .price {
        align-items: center;
        display: flex;
        font-weight: 600;
        height: 32px;
        justify-content: space-between;
        padding: 0 6px;
        width: 100%;

        &:hover,
        &:active {
          background-color: rgba(0,0,0,.4);
        }

        img {
          width: 20px;
        }
      }

      &.immersive {
        a {
          display: none;
        }

        .nft-video,
        .nft-image,
        .img {
          height: 100%;
        }

        .price {
          display: none;
        }
      }
    }
  }

  .footer {
    align-items: flex-end;
    background-color: rgba(0,0,0,.8);
    bottom: 0;
    color: (255, 255, 255, 0.5);
    display: flex;
    font-size: 10px;
    height: 50vh;
    justify-content: space-between;
    left: 0;
    padding: 0 12px 6px;
    position: fixed;
    transition: height 600ms;
    width: 100%;
    z-index: 3;

    .address {
      margin-left: 6px;
    }

    button {
      align-items: center;
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      display: none;
      outline: none;
    }

    &.loaded {
      align-items: center;
      height: $footerHeight;

      button {
        border: 2px solid white;
        display: flex;
        font-size: 16px;
      }
    }
  }

  @media (min-width: 600px) {
    header {
      padding: 0 32px;
      flex-direction: row;

      span {
        margin-left: 32px;
      }
    }
  }

  &.hide {
    header {
      display: none;
    }
  }
}

.nft-modal {
  align-items: center;
  display: flex;

  .selected-nft,
  .all-settings-container {
    background-color: #222;
    border: 4px solid rgba(255, 255, 255, 0.9);
    color: white;
    margin: 0 auto;
    outline: none;
    padding: 12px;
    position: relative;
    top: 0;
    min-width: calc(100vw - 32px);
  }

  .selected-nft {
    left: 16px;
    transition: left 600ms;

    &.hide {
      left: -100vw
    }

    header {
      h3 {
        margin: 0 0 8px;
        text-align: center;
      }
    }

    h4 {
      margin: 24px 0 4px;
    }

    .filter-options {
      .filter-option {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 4px 0 4px 8px;

        &:hover,
        &:active {
          background-color: rgba(0,0,0,.4);
        }

        button {
          background: none;
          border: none;
          color: white;
          cursor: pointer;
        }
      }
    }

    .external-link,
    .view-all-filters {
      align-items: center;
      background-color: #222;
      border: 4px solid rgba(255, 255, 255, 0.9);
      bottom: -60px;
      color: white;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      height: 48px;
      outline: none;
      padding: 0 8px;
      position: absolute;

      &:hover,
      &:active {
        background-color: rgb(26, 26, 26);
      }
    }

    .external-link {
      left: -4px;
      text-decoration: none;
    }

    .view-all-filters {
      right: -4px;
    }
  }

  .all-settings-container {
    right: 0;
    transition: right 600ms;

    &.wide {
      right: calc(100vw - 48px);
    }

    &.hide {
      right: -100vw;
    }

    h3 {
      margin: 0;
    }

    h4 {
      margin: 0;
    }

    .settings {
      .setting {
        align-items: center;
        display: flex;
      }

      .controls {
        flex-direction: row;
        margin-left: auto;

        span {
          font-size: 14px;
        }
        
        span * {
          font-size: 14px;
        }

        svg {
          fill: white;
        }
      }
    }

    .custom-filters {
      h4 {
        margin: 16px 0 0;
      }
    }
  }
}