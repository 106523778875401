.dbo {
  color: white;
  height: 100vh;
  overflow: auto;

  .schedule,
  .schedules {
    .by-time,
    .by-country {
      .time,
      .country {
        display: inline-block;
        vertical-align: top;
        width: 25%;
      }
    }
  }

  .matchup {
    margin-left: 16px;
  }
}