$purple: #2e294e;
$blue: #334fff;
$lightblue: #67aaf9;
$green: #49beaa;
$pink: #9d5c63;
$yellow: #EEB868;

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.page-container {
  background-color: #e4e4e4;
  height: 100vh;
  left: 0;
  position: fixed;
  width: 100vw;
  top: 0;
}

.activity-container {
  align-items: flex-start;
  display: flex;
  height: 100vh;
  margin: 0 auto;
  padding: 16px;
  width: 100vw;

  .mid-container {
    width: 100%;

    .refresh,
    .filter-menu,
    .main-toggle,
    .watchlist-menu {
      align-items: center;
      background-color: black;
      border: 2px solid black;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      display: inline-flex;
      float: right;
      margin-bottom: 8px;

      &.loading {
        i {
          animation: spin 1s infinite;
        }
      }

      &:hover,
      &:active,
      &:focus {
        background-color: white;
        color: black;
      }
    }

    .filter-menu,
    .main-toggle,
    .watchlist-menu {
      float: unset;
    }

    .filter-menu,
    .watchlist-menu {
      @media (min-width: 1400px) {
        display: none;
      }
    }

    .main-toggle,
    .watchlist-menu {
      margin-left: 6px;

      @media (min-width: 1400px) {
        margin: 0;
      }
    }

    @media (min-width: 1400px) {
      margin: 0 16px;
      width: auto;
    }
  }

  .table-container {
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding-bottom: 40px;
    width: 100%;

    .listing {
      align-items: center;
      background-color: white;
      border: 2px solid black;
      border-radius: 6px;
      display: flex;
      left: 0;
      margin: 12px auto 48px;
      min-height: 210px;
      padding: 6px;
      position: relative;
      top: 0;
      transition: all 300ms;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }

      &.streak {
        margin-bottom: 62px;

        @media (min-width: 600px) {
          margin-bottom: 28px;
        }
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &.collapsed {
        height: 36px;
        margin-bottom: 12px;
        min-height: 0;

        &:hover {
          cursor: pointer;
        }

        &.streak {
          margin-bottom: 28px;
        }

        .info-box {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-left: 200px;
          padding: 0;
          width: calc(100% - 200px);

          .item-name,
          .price {
            margin: 0;

            .steal {
              &:hover {
                .steal-text { 
                  display: none;
                }
              }
            }

            .amount {
              @media (min-width: 600px) {
                display: none;
              }
            }

            .price-change:hover .average {
              display: none;
            }
          }

          .item-name {
            display: none;
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media (min-width: 600px) {
              display: block;
            }
          }

          @media (min-width: 600px) {
            justify-content: space-between;
            width: calc(100% - 324px);
          }
        }

        .time-passed {
          display: none;

          @media (min-width: 600px) {
            display: block;
          }
        }

        .streak-container {
          bottom: -30px;
        }
      }

      &.green {
        border-color: $green;

        .streak-container {
          > * {
            background-color: $green;
            color: $green;

            span {
              border-color: $green;
            }
          }
        }
      }

      &.blue {
        border-color: $blue;

        .streak-container {
          > * {
            background-color: $blue;
            color: $blue;

            span {
              border-color: $blue;
            }
          }
        }
      }

      &.lightblue {
        border-color: $lightblue;

        .streak-container {
          > * {
            background-color: $lightblue;
            color: $lightblue;

            span {
              border-color: $lightblue;
            }
          }
        }
      }

      &.purple {
        border-color: $purple;

        .streak-container {
          > * {
            background-color: $purple;
            color: $purple;

            span {
              border-color: $purple;
            }
          }
        }
      }

      &.orange {
        border-color: orange;

        .streak-container {
          > * {
            background-color: orange;
            color: orange;

            span {
              border-color: orange;
            }
          }
        }
      }

      &.yellow {
        border-color: $yellow;

        .streak-container {
          > * {
            background-color: $yellow;
            color: $yellow;

            span {
              border-color: $yellow;
            }
          }
        }
      }

      &.pink {
        border-color: $pink;

        .streak-container {
          > * {
            background-color: $pink;
            color: $pink;

            span {
              border-color: $pink;
            }
          }
        }
      }

      &.gray {
        border-color: gray;

        .streak-container {
          > * {
            background-color: gray;
            color: gray;

            span {
              border-color: gray;
            }
          }
        }
      }

      .label {
        align-items: center;
        color: white;
        display: flex;
        font-weight: 600;
        height: 32px;
        justify-content: flex-start;
        left: 0;
        position: absolute;
        top: 0;
        width: 200px;

        .watchlist-button {
          cursor: pointer;
          margin-left: auto;
          margin-right: 32px;
        }

        .collapse-button {
          cursor: pointer;
          margin-left: -32px;
          margin-right: 24px;
        }

        &::after {
          content: "";
          border-top: 32px solid green;
          border-right: 32px solid white;
          right: -4px;
          position: absolute;
        }

        &.green {
          background-color: $green;

          &::after {
            border-top-color: $green;
          }
        }
  
        &.blue {
          background-color: $blue;

          &::after {
            border-top-color: $blue;
          }
        }
  
        &.lightblue {
          background-color: $lightblue;

          &::after {
            border-top-color: $lightblue;
          }
        }
  
        &.purple {
          background-color: $purple;

          &::after {
            border-top-color: $purple;
          }
        }
  
        &.orange {
          background-color: orange;

          &::after {
            border-top-color: orange;
          }
        }
  
        &.yellow {
          background-color: $yellow;

          &::after {
            border-top-color: $yellow;
          }
        }
  
        &.pink {
          background-color: $pink;

          &::after {
            border-top-color: $pink;
          }
        }
  
        &.gray {
          background-color: gray;

          &::after {
            border-top-color: gray;
          }
        }

        i {
          margin: 0 12px 0 8px;
        }

        span {
          padding-bottom: 2px;
        }
      }

      .time-passed {
        padding-bottom: 2px;
        position: absolute;
        top: 6px;
        right: 6px;
      }

      .from,
      .info-box,
      .to {
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        overflow: hidden;
        width: calc(100% / 3);
      }

      .from,
      .to {
        .avatar-container {
          border-radius: 50%;
          height: 32px;
          overflow: hidden;
          width: 32px;

          img {
            height: 100%;
          }
        }

        p {
          font-weight: 600;
          margin: 0 8px 6px;
          text-align: center;
          width: 100%;
        }
      }

      .info-box {
        padding-top: 32px;

        .item-name {
          font-weight: bold;
          margin-bottom: 6px;
          text-align: center;
        }
        
        img,
        video {
          border-radius: 4px;
          height: 0;
          transition: height 600ms;

          &.show {
            height: 142px;
          }
        }

        .price {
          align-items: center;
          display: inline-flex;
          margin-top: 6px;

          &.anti-trend {
            font-weight: bold;
          }

          .steal {
            .steal-text {
              background-color: white;
              border: 2px solid black;
              border-radius: 4px;
              display: none;
              padding: 4px 6px;
              position: absolute;
              left: calc(50% - 75px);
              top: calc(50% - 60px);
              width: 150px;
            }
  
            &:hover {
              .steal-text { 
                display: inline;
              }
            }
          }

          .price-change {
            align-items: center;
            display: inline-flex;
            margin-left: 4px;

            .average-change {
              display: none;

              @media (min-width: 600px) {
                display: inline;
              }
            }

            .indicator {
              font-size: 18px;

              @media (min-width: 600px) {
                display: none;
              }
            }

            &.red {
              color: red;

              .indicator {
                transform: rotate(90deg);
              }
            }
  
            &.green {
              color: green;

              .indicator {
                transform: rotate(-90deg);
              }
            }

            .average {
              background-color: white;
              border: 2px solid black;
              border-radius: 4px;
              display: none;
              padding: 4px 6px;
              position: absolute;
              left: calc(50% - 75px);
              top: calc(50% - 20px);
              width: 150px;
            }

            &:hover {
              .average {
                display: block;
              }
            }
          }
        }

        @media (min-width: 600px) {
          padding-top: 0;
        }
      }

      .link {
        align-items: center;
        background-color: black;
        border: 2px solid black;
        border-radius: 4px;
        bottom: -36px;
        color: white;
        display: flex;
        height: 32px;
        justify-content: center;
        line-height: 16px;
        padding-bottom: 2px;
        position: absolute;
        outline: none;
        right: 0;
        text-decoration: none;
        width: 100%;

        &:hover,
        &:active,
        &:focus {
          background-color: white;
          color: black;
        }

        @media (min-width: 600px) {
          bottom: 6px;
          right: 6px;
          width: 190px;
        }
      }

      .streak-container {
        align-items: center;
        bottom: -64px;
        display: flex;
        font-size: 13px;
        height: 28px;
        justify-content: space-between;
        left: 0;
        position: absolute;
        width: 100%;

        .from-user,
        .to-user,
        .item {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          position: relative;
          visibility: hidden;
          width: 8px;

          &.visible {
            visibility: visible;
          }

          &:hover {
            .message {
              display: flex;
              opacity: 1;
            }
          }

          .count {
            align-items: center;
            background-color: white;
            border: 2px solid;
            border-radius: 50%;
            display: flex;
            font-weight: bold;
            height: 20px;
            justify-content: center;
            min-width: 20px;
            width: 20px;
          }

          .message {
            align-items: center;
            background-color: white;
            border-radius: 4px;
            display: none;
            font-size: 16px;
            justify-content: center;
            opacity: 0;
            padding: 2px 6px;
            position: absolute;
            text-align: center;
            transition: all 600ms;
            max-width: 280px;
            white-space: nowrap;
            z-index: 3;

            span {
              display: inline-block;
              margin-right: 4px;
              max-width: 92px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              @media (min-width: 600px) {
                max-width: 200px;
              }
            }

            @media (min-width: 600px) {
              max-width: 400px;
            }
          }
        }

        .from-user {
          margin-left: 24px;
          margin-right: auto;

          @media (min-width: 600px) {
            margin-left: 100px;
          }
        }

        .item {
          margin: 0 auto;
        }

        .to-user {
          margin-left: auto;
          margin-right: 24px;

          @media (min-width: 600px) {
            margin-right: 100px;
          }
        }

        @media (min-width: 600px) {
          bottom: -30px;
        }
      }

      @media (min-width: 600px) {
        margin-bottom: 12px;
      }
    }

    @media (min-width: 600px) {
      width: 600px;
    }
  }

  @media (min-width: 1400px) {
    max-width: 1400px;
  }
}


.filters-container {
  display: none;
  margin-top: -16px;
  width: 320px;

  .filter {
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 32px;
    margin: 4px 0;
    padding: 8px 12px;
    width: 100%;

    &.selected {
      color: white;

      &.green {
        background-color: $green;
      }

      &.blue {
        background-color: $blue;
      }

      &.lightblue {
        background-color: $lightblue;
      }

      &.purple {
        background-color: $purple;
      }

      &.orange {
        background-color: orange;
      }

      &.yellow {
        background-color: $yellow;
      }

      &.pink {
        background-color: $pink;
      }

      &.gray {
        background-color: gray;
      }
    }
  }

  @media (min-width: 1400px) {
    display: block;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;

  > i {
    color: white;
    cursor: pointer;
    font-size: 32px;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 3;
  }

  .filters-container {
    background-color: white;
    border: 2px solid black;
    border-radius: 4px;
    display: block;
    left: 0;
    margin: 48px 12px 0;
    padding: 0 8px 4px;
    width: calc(100% - 24px);

    .filter {
      color: white;
      display: inline-flex;
      height: 40px;
      margin: 8px 0;
      width: calc(50% - 4px);

      &:nth-child(even) {
        margin-right: 8px;
      }
    }
  }
}

body
{
  background: #111;
}

.shiny
{
  color: #F5C21B;
  background: gradient(linear, left top, left bottom, from(#F5C21B), to(#D17000));
  background-clip: text;
  display: inline-block;
  font-size: 16px;
  font-weight: 900;
  position: relative;
  text-transform: uppercase;
}

.shiny::before
{
	background-position: -180px;
	animation: flare 5s infinite;
  animation-timing-function: linear;
  background-image: linear-gradient(65deg, transparent 20%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.3) 27%, transparent 27%, transparent 100%);
  background-clip: text;
  content: "";
  color: #FFF;
  display: block;
  position: absolute;
}

.inner-shiny::after, .inner-shiny::before
{
  animation: sparkle 2s infinite;
  animation-timing-function: linear;
	background: #FFF;
  border-radius: 100%;
  box-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #FFF, 0 0 25px #FFF, 0 0 30px #FFF, 0 0 35px #FFF;
  content: "";
  display: block;
  height: 4px;
  opacity: 0.7;
  position: absolute;
  width: 4px;
}

.inner-shiny::before
{
  height: 2px;
  left: 0;
  bottom: 4px;
  width: 2px;
}

.inner-shiny::after
{
	animation-delay: 0.8s;
  top: 2px;
  right: -3px;
}

.offer-watch {
  background-color: white;
  border: 2px solid black;
  border-radius: 4px;
  margin: 48px auto;
  max-height: calc(100vh - 80px);
  overflow: hidden;
  padding: 12px;
  width: calc(100% - 18px);

  &.no-mobile {
    @media (max-width: 1400px) {
      display: none;
    }
  }

  header {
    align-items: center;
    display: flex;

    > button {
      margin-left: auto;
    }
  }

  h2 {
    margin: 0;
    padding: 0;
  }

  input {
    height: 32px;
  }

  button {
    align-items: center;
    background-color: black;
    border: 2px solid black;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    display: inline-flex;
    float: right;
    height: 32px;
    margin-left: 8px;

    &.loading {
      i {
        animation: spin 1s infinite;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: white;
      color: black;
    }
  }

  table {
    width: 100%;

    .name {
      width: 30%;
    }

    .remove {
      width: 12%;
    }

    thead {
      display: table;
      table-layout: fixed;
      width: 100%;

      th {
        text-align: center;

        &.no-mobile {
          @media (max-width: 1400px) {
            display: none;
          }
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }

        &.highest,
        &.lowest,
        &.last {
          border-radius: 4px;
          color: white;
        }

        &.highest {
          background-color: $green ;
        }

        &.lowest {
          background-color: $lightblue ;
        }

        &.last {
          background-color: $blue ;
        }
      }
    }

    tbody {
      display: block;
      max-height: calc(100vh - 243px);
      overflow: auto;
      padding-bottom: 24px;
      
      tr {
        display: table;
        table-layout: fixed;
        width: 100%;
      }

      td {
        text-align: center;

        &.no-mobile {
          @media (max-width: 1400px) {
            display: none;
          }
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }

      @media (min-width: 1400px) {
        max-height: calc(100vh - 246px);
      }
    }
  }

  @media (min-width: 1400px) {
    max-height: calc(100vh - 104px);
    width: 500px;
  }
}

@keyframes flare
{
  0%   { background-position: -180px; }
  30%  { background-position: 500px; }
  100% { background-position: 500px; }
}

@keyframes sparkle
{
  0%   { opacity: 0; }
  30%  { opacity: 0; }
  40%  { opacity: 0.8; }
  60%  { opacity: 0; }
  100% { opacity: 0; }
}