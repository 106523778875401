@import '../../../utils/mixins';

.console-container {
  position: relative;

  .banner {
    border-radius: 4px;
    left: 8px;
    padding: 16px;
    position: absolute;
    top: 6px;
    width: calc(100% - 16px);

    &.error {
      background-color: lightcoral;
      border: 1px solid darkred;
      color: darkred;
    }
  }

  .wishlists-container {
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 16px;

    .overload {
      color: red;
      cursor: pointer;
    }

    .get-wishlists,
    .clean-wishlists {
      display: inline-block;
      width: calc(50% - 8px);
    }

    .clean-wishlists {
      margin-left: 16px;
    }

    .user-wishlists-container {
      .list {
        max-height: 400px;
        overflow-y: auto;
      }

      .user {
        &:not(:last-child) {
          border-bottom: 1px solid #e8e8e8;
          margin-bottom: 8px;
          padding-bottom: 8px;
        }
      }

      .top {
        align-items: center;
        cursor: pointer;
        display: flex;
      }

      .display-name {
        font-weight: bold;
        font-size: 18px;
      }

      .last-signin {
        margin-left: auto;
      }

      .wishlist-amount {
        margin-left: 6px;
      }
    }
  }

  .items-container {
    .quick-update,
    .update-algolia,
    .open-manager {
      display: inline-block;
      width: calc(50% - 8px);
    }

    .quick-update {
      margin-right: 16px;
    }

    .item-manager {
      background-color: #E4E4E4;
      padding: 16px;

      img {
        display: block;
        margin: 0 auto;
        width: 400px;
      }
    }

    .updaters {
      @media (min-width: 1200px) {
        align-items: center;
        display: flex;
      }
    }
  }

  .news-container {
    .articles-container {
      .article {
        align-items: center;
        display: flex;

        .switch {
          margin-left: auto;
        }
      }
    }
  }

  .challenges-container,
  .missions-container {
    position: relative;

    header {
      background-color: #E4E4E4;
      height: 88px;
      margin: -16px -16px 0 -16px;
      opacity: 0;
      overflow: hidden;
      padding: 16px;
      transition: opacity 300ms;

      &.show {
        opacity: 1;
      }

      span {
        &:first-child {
          display: block;
          font-size: 14px;
        }

        &:last-child {
          font-size: 32px;
          font-weight: bold;
        }
      }
    }

    .submit-mission {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    .missions-list,
    .challenges-list {
      h4 {
        margin-bottom: 8px;
      }

      .mission,
      .challenge {
        align-items: center;
        background-color: #E4E4E4;
        border-radius: 4px;
        cursor: pointer;
        color: $purple;
        display: flex;
        font-size: 18px;
        height: 40px;
        margin: 4px 0;
        padding: 4px 6px;

        button {
          background-color: $purple;
          color: white;
          margin-left: auto;
          width: 200px;
        }

        input {
          border: 0;
          font-size: 18px;
          height: 100%;
          margin: 0 8px 0 4px;
          outline: none;
          width: 24px;
          
          &.edit-name {
            min-width: 400px;
          }
        }

        &.selected {
          cursor: default;
        }

        &.active {
          background-color: transparentize($purple, 0.2);
          color: white;
          font-weight: bold;
        }
      }
    }

    .challenge-buttons {
      left: 16px;
      position: absolute;
      top: 302px;
      width: calc(100% - 32px);

      button {
        display: inline-block;
        margin: 0 -16px 0 8px;
        width: calc(50% + 8px);

        &:first-child {
          margin: 0 8px 0 -16px;
        }
      }
    }
  }

  .challenges-container {
    border-bottom: 2px solid black;

    .submit-challenge {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 78px;
    }
  }
}
