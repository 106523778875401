@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.crew-challenge-container {
  background-color: #646d80;
  color: white;
  font-size: 18px;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  width: 100vw;

  .info-button {
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 200;
    margin-top: 16px;
    text-transform: uppercase;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 6px 0 rgba(32,33,36,.28);
  justify-content: center;
  font-size: 22px;
  height: 80px;
  color: white;

  span {
    cursor: pointer;

    &:first-child {
      margin-right: 16px;
    }
    
    &:last-child {
      margin-left: 16px;
    }
  }
}

.password-view {
  background-color: white;
  border: 1px solid #E4E4E4;
  border-radius: 2px;
  color: black;
  margin: 20px auto 0;
  padding: 16px;
  width: 400px;

  form {
    align-items: center;
    display: flex;

    button {
      margin-left: 16px;
    }
  }
}

.logged-in-container {
  .settings-container,
  .explore-container,
  .prev-scoreboard-container,
  .console-container {
    background-color: white;
    color: black;
    height: calc(100vh - 80px);
    left: 100vw;
    overflow: hidden;
    padding: 16px;
    position: absolute;
    top: 80px;
    transition: left 300ms;
    width: 100vw;

    &.show {
      left: 0;
    }

    header {
      align-items: center;
      display: flex;

      h3 {
        margin: 0
      }

      .close {
        cursor: pointer;
        margin: 0 -8px 0 auto;
        padding: 8px;
      }
    }

    .settings-list {
      margin-top: 8px;

      .setting {
        align-items: center;
        display: flex;
        justify-content: space-between;
        text-align: left;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .setting-label {
          margin-bottom: 4px;
        }

        .description {
          display: block;
          font-size: 14px;
          line-height: 14px;
        }
      }

      .delete-button {
        border: none;
        border-radius: 4px;
        background-color: #F65D60;
        color: white;
        cursor: pointer;
        font-size: 16px;
        margin-top: 16px;
        padding: 4px 6px;
        width: 100%;
      }
    }

    .confirm-delete-container {
      background-color: transparentize(black, 0.4);
      display: none;
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;

      &.show {
        display: block;
      }

      .confirm-delete {
        background-color: white;
        margin: 80px auto 0;
        padding: 12px;
        width: calc(100% - 16px);

        .button-container {
          button {
            background-color: white;
            border: 2px solid #F65D60;
            color: #F65D60;
            cursor: pointer;
            font-size: 16px;
            padding: 4px 6px;
            width: calc(50% - 8px);

            &.confirm {
              background-color: #F65D60;
              color: white;
            }

            &:last-child {
              margin-left: 16px;
            }
          }
        }

        @media (min-width: 1200px) {
          width: 600px;
        }
      }
    }

    @media (min-width: 1200px) {
      display: none;
      width: 600px;

      &.show {
        display: block;
        left: calc(50vw - 300px);
      }
    }
  }

  .console-container {
    width: 100%;

    &.show {
      left: 0;

      @media (min-width: 1200px) {
        left: calc(50% - 600px);
      }
    }

    @media (min-width: 1200px) {
      width: 1200px;
    }
  }

  .greeting {
    align-items: center;
    color: white;
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    padding: 12px;

    p {
      margin: 0;
    }

    .refresh,
    .explore,
    .settings {
      cursor: pointer;
      height: 40px;
      padding: 8px;
      width: 40px;

      &.spin {
        animation: spin 1s infinite;
      }
    }

    .refresh {
      margin-left: -8px;
    }

    .settings {
      margin-right: -8px;
    }

    @media (min-width: 1200px) {
      width: 600px;
      margin: 0 auto;
    }
  }

  .bottom-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 74px;
    overflow: hidden;
    padding: 8px 0;

    .view-prev {
      background-color: #222;
      color: white;
      cursor: pointer;
      font-size: 16px;
      height: 40px;
      margin-top: 8px;
      padding: 4px;
      text-transform: none;
      width: 120px;
    }

    .total {
      font-size: 14px;
      padding: 4px;
      width: 100%;

      &.hasbutton {
        width: calc(100% - 128px);
      }

      p {
        margin: 0;

        &:first-child {
          font-size: 16px;
        }
      }
    }
  }

  
}

.no-athlete {
  color: white;
  font-size: 18px;
  padding: 16px;
  text-align: center;

  p {
    margin: 0 auto 16px;
    width: 100%;

    @media (min-width: 1200px) {
      text-align: center;
    }
  }

  .login-buttons {
    button {
      border: none;
      color: white;
      display: block;
      font-size: 16px;
      margin: 0 auto;
      padding: 6px 12px;
      width: 200px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .strava-login {
      background-color: #fc5200;
  
      &:hover {
        background-color: transparentize(#fc5200, 0.4);
      }
    }

    .fitbit-login {
      background-color: #4cbfbf;
  
      &:hover {
        background-color: transparentize(#4cbfbf, 0.4);
      }
    }
  }
}

.info-modal-container {
  background-color: transparentize(black, 0.2);
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;

  &.open {
    display: block;

    .info-modal {
      opacity: 1;
    }
  }

  .info-modal {
    background-color: white;
    border-radius: 4px;
    color: black;
    margin: 80px 16px 16px;
    height: calc(100vh - 88px);
    opacity: 0;
    position: relative;
    padding: 16px;
    transform: opacity 300ms;

    .close {
      color: white;
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: -64px;
    }

    .modal-content {
      overflow-y: auto;
      max-height: 100%;
    }

    .info-item {
      text-align: left;

      &:not(:first-child) {
        margin-top: 16px;
      }

      label {
        display: block;
        font-weight: 600;
        margin-bottom: 4px;
      }

      p {
        margin: 0;
      }

      .tracker-info {
        background-color: #e4e4e4;
        margin-top: 8px;
        padding: 8px;

        p {
          &:not(:first-child) {
            margin-top: 8px;
          }

          span {
            display: block;

            &:first-child {
              font-weight: 600;
            }
          }
        }
      }
    }

    @media (min-width: 1200px) {
      margin: 80px auto;
      width: 600px;
    }
  }
}

.scoreboard-container {
  margin: 0 auto;
  max-height: calc(100vh - 148px);
  max-width: 600px;
  overflow: none;
  width: calc(100% - 20px);

  .entry-container {
    box-sizing: border-box;
    max-height: calc(100vh - 218px);
    overflow-y: auto;

    &.refreshing {
      border: 1px solid orange;
      margin: -1px;
    }
  }

  .scoreboard-entry {
    border-radius: 0;
    display: none;
    max-height: 54px;
    transition: max-height 200ms;

    &.show {
      display: block;
    }

    &.self {
      display: block;
      font-weight: 600;

      &:not(.show) {
        color: transparentize(black, 0.8);
      }
    }

    .activities {
      display: none;
      font-weight: 400;
      padding: 0 0 8px 0;

      p {
        font-size: 14px;
      }

      .activities-header {
        margin-bottom: 4px;

        h3 {
          align-items: center;
          display: flex;
          font-size: 24px;
          font-weight: 600;
          justify-content: center;
          margin: 0 auto;

          span {
            display: inline-block;
            font-weight: 100;
            font-size: 11px;
            margin-left: 6px;
            text-transform: uppercase;
          }
        }
      }

      .activity-list {
        max-height: 100px;
        overflow-y: auto;

        .activity {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          width: 200px;
  
          .activity-icon {
          }
  
          .activity-distance {
          }
  
          .activity-date {
            font-size: 14px;
          }
        }
      }

    }

    &.open {
      max-height: 400px;

      .card-contents {
        padding-bottom: 6px;
      }

      .scoreboard-name {
        .material-icons {
          transform: rotate(-180deg);
        }
      }
      
      .activities {
        display: block;
      }
    }

    &:hover {
      background-color: #E4E4E4;
    }

    .card-contents {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 16px;
      position: relative;

      .scoreboard-name {
        align-items: center;
        display: flex;
        width: calc(100% - 116px);

        .content {
          display: block;
          margin-right: 4px;
          max-width: 192px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.self {
            max-width: 100%;

            @media (min-width: 1200px) {
              max-width: 390px;
            }
          }

          @media (min-width: 1200px) {
            max-width: 436px;
          }
        }

        .crown {
          height: 30px;
          position: absolute;
          top: -2px;
          left: 2px;
          transform: rotate(-20deg);
          width: 30px;
        }

        .material-icons {
          margin-left: 8px;
          transition: transform 300ms;
        }
      }

      .distance {
        margin-left: auto;
      }
    }
  }
}

.prev-scoreboard-container {
  .scoreboard-container {
    .entry-container {
      box-shadow: 0 1px 6px 0 rgba(32,33,36,.28);
      border-radius: 4px;
      max-height: calc(100vh - 184px);

      .scoreboard-entry {
  
        .card-contents {
          cursor: initial;

          &:hover {
            background-color: white;
          }
  
          .scoreboard-name {
            width: calc(100% - 108px);
          }
        }
      }
    }

    .total {
      align-items: center;
      background-color: #282c34;
      color: white;
      display: flex;
      height: 40px;
      justify-content: center;
      margin: 8px -26px 0;
    }
  }
}