.filter {
  align-items: center;
  display: flex;
  margin: 0 -6px;
  justify-content: space-between;
  text-align: center;

  > button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }

  > span.fade {
    color: rgb(135, 135, 135);
  }

  .switch {
    margin-top: 2px;

    .MuiSwitch-track {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .MuiSwitch-thumb {
      color: rgb(135, 135, 135);
    }

    &.checked {
      .MuiSwitch-track {
        background-color: rgba(255, 255, 255, 0.8);
      }

      .MuiSwitch-thumb {
        color: white;
      }
    }
  }
}