@import '../../utils/mixins';

.console-container {
  background-color: white;

  .app-bar {
    z-index: 4;
  }

  .console-content {
    padding: 16px;
  }

  section {
    color: black;
    background-color: white;
    border-radius: 4px;
    margin: 8px 0;
    width: 100%;
  }

  button.cta {
    background-color: $purple;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 18px;
    height: 48px;
    margin-top: 16px;
    width: 100%;

    &:disabled {
      background-color: transparentize($purple, 0.6);
      cursor: default;
    }
  }

  @media (min-width: 1200px) {
    margin: 0 auto;
    width: 1200px;
  }
}
