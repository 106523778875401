.writeup-container {
    max-height: 100vh;
    overflow-y: auto;
    width: 100vw;

    .writeup {
        color: white;
        margin: 0 auto;
        padding: 8px;

        header {
            align-items: center;
            display: flex;

            .button {
                background-color: transparent;
                border: none;
                color: white;
                cursor: pointer;
                margin-left: -8px;
                padding: 8px;
                text-decoration: none;
            }
    
            h2 {
                background-color: #222;
                margin: 0 0 0 auto;
                padding: 16px;
                width: calc(100% - 40px);
            }
        }
    
        .pane {
            margin: 8px 0;
    
            &:last-child {
                margin-bottom: 0;
            }
    
            .left {
                align-items: center;
                background-color: #222;
                display: flex;
                padding: 8px;

                @media (min-width: 1200px) {
                    margin-right: 4px;
                    padding: 16px;
                }
            }
    
            .right {
                background-color: #222;
                padding: 8px;

                @media (min-width: 1200px) {
                    margin-left: 4px;
                    padding: 16px;
                }
            }

            @media (min-width: 1200px) {
                align-items: stretch;
                display: flex;
            }
        }
        
        @media (min-width: 1200px) {
            width: 1200px;
        }
    }
}