.fd-writeup {
    .content {
        font-size: 18px;
    }

    .reception {
        .right {
            img {
                display: block;
                margin: 0 auto;
                width: calc(50% - 16px);

                &:not(:last-child) {
                    margin-bottom: 8px;
                }

                &.full {
                    display: block;
                    width: 100%;
                }
            }

            @media (min-width: 1200px) {
                min-width: 60%;
            }
        }
    }

    .mediaCoverage {
        a {
            display: block;
            margin: 8px 0;
        }

        .right {
            > div {
                align-items: center;
                display: flex;
                height: 100%;
                justify-content: space-between;
                padding: 0 24px;
            }

            img {
                width: 100px;
            }

            @media (min-width: 1200px) {
                min-width: 30%;
            }
        }
    }
}