.item-shop-updater, .bulk-item-creater, .news-container, .wishlist-sender {
  textarea {
    margin-top: 16px;
    width: 100%;
  }
}

.input {
  margin: 8px;
  vertical-align: top;
  width: calc(50% - 16px);

  &:nth-child(even) {
    margin-left: 16px;
  }
}

.challenge-creater {
  .mission {
    width: calc(100% - 160px);
  }

  .prestige {
    margin-left: 16px;
  }
}
